// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ProjectsAPI {
  constructor(Client, DownloadClient, UploadClient, config) {
    this.Client = Client;
    this.DownloadClient = DownloadClient;
    this.UploadClient = UploadClient;
    this.config = config;
  }

  dashboard = () => {
    return this.Client.get(ENDPOINTS.PROJECTS.DASHBOARD).then(
      (response) => response.data.data
    );
  };

  list = (params) => {
    return this.Client.get(ENDPOINTS.PROJECTS.LIST, { params }).then(
      (response) => response.data
    );
  };

  get_by_id = (id, params) => {
    return this.Client.get(ENDPOINTS.PROJECTS.GET_BY_ID(id), { params }).then(
      (response) => response.data
    );
  };

  store = (data, params) => {
    return this.Client.post(ENDPOINTS.PROJECTS.STORE, data, { params }).then(
      (response) => response.data.data
    );
  };

  update = (id, data, params) => {
    return this.Client.put(ENDPOINTS.PROJECTS.UPDATE(id), data, {
      params,
    }).then((response) => response.data);
  };

  delete = (data) => {
    return this.Client.delete(ENDPOINTS.PROJECTS.DELETE(data)).then(
      (response) => response
    );
  };

  list_reports = (id, params) => {
    return this.Client.get(ENDPOINTS.PROJECTS.REPORTS(id), { params }).then(
      (response) => response.data
    );
  };

  add_configurations = (id, data, params) => {
    return this.Client.put(ENDPOINTS.PROJECTS.ADD_CONFIGURATIONS(id), data, {
      params,
    }).then((response) => response.data.data);
  };

  remove_configurations = (id, data, params) => {
    return this.Client.put(ENDPOINTS.PROJECTS.REMOVE_CONFIGURATIONS(id), data, {
      params,
    }).then((response) => response.data.data);
  };

  remove_operator = (id, data, params) => {
    return this.Client.put(ENDPOINTS.PROJECTS.REMOVE_OPERATOR(id), data, {
      params,
    }).then((response) => response.data.data);
  };

  restore_reports = (project_id, data) => {
    return this.Client.put(
      ENDPOINTS.PROJECTS.RESTORE_REPORTS(project_id),
      data
    ).then((response) => response);
  };

  remove_report = (project_id, pile_id, params) => {
    return this.Client.delete(
      ENDPOINTS.PROJECTS.REMOVE_REPORT(project_id, pile_id),
      params
    ).then((response) => {
      return response;
    });
  };

  upload_pile_list = (id, data) => {
    return this.UploadClient.post(
      ENDPOINTS.PROJECTS.UPLOAD_PILE_LIST(id),
      data
    ).then((response) => response);
  };

  get_pile_list_url = (id) => {
    return this.Client.get(ENDPOINTS.PROJECTS.GET_XLSX_URL(id));
  };

  download_pile_selection = (projectId, selection) =>
    this.Client.post(ENDPOINTS.PROJECTS.DOWNLOAD_REPORTS(projectId), {
      selection,
    }).then((response) => response.data);
}

export default ProjectsAPI;
