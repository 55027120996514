// Imports => Constants
import { ENDPOINTS } from '@constants';

export class HydrohammerTypesAPI {
	constructor(Client, UploadClient, config) {
		this.Client = Client;
		this.UploadClient = UploadClient;
		this.config = config;
	}

	list = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT_TYPES.HYDROHAMMERS.LIST, {
			params,
		}).then((response) => response.data);
	};

	table = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT_TYPES.HYDROHAMMERS.TABLE, {
			params,
		}).then((response) => response.data);
	};

	get_by_id = (id) => {
		return this.Client.get(
			ENDPOINTS.EQUIPMENT_TYPES.HYDROHAMMERS.GET_BY_ID(id)
		).then((response) => response.data.data);
	};

	store = (data) => {
		return this.Client.post(
			ENDPOINTS.EQUIPMENT_TYPES.HYDROHAMMERS.STORE,
			data
		).then((response) => response.data.data);
	};

	update = (id, data, options) => {
		const client =
			options && options.upload ? this.UploadClient.post : this.Client.put;

		return client(ENDPOINTS.EQUIPMENT_TYPES.HYDROHAMMERS.UPDATE(id), data).then(
			(response) => response.data.data
		);
	};

	delete = (data) => {
		return this.Client.delete(
			ENDPOINTS.EQUIPMENT_TYPES.HYDROHAMMERS.DELETE(data)
		).then((response) => response);
	};

	delete_media = (data) => {
		return this.Client.delete(
			ENDPOINTS.EQUIPMENT_TYPES.HYDROHAMMERS.MEDIA(data)
		).then((response) => response.data.data);
	};
}

export default HydrohammerTypesAPI;
