// Imports => Constants
import { ENDPOINTS } from '@constants';

export class SleevesAPI {
	constructor(Client, config) {
		this.Client = Client;
		this.config = config;
	}

	list = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT.SLEEVES.LIST, {
			params,
		}).then((response) => response.data);
	};

	table = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT.SLEEVES.TABLE, {
			params,
		}).then((response) => response.data);
	};

	get_by_id = (id) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT.SLEEVES.GET_BY_ID(id)).then(
			(response) => response.data.data
		);
	};

	store = (data) => {
		return this.Client.post(ENDPOINTS.EQUIPMENT.SLEEVES.STORE, data).then(
			(response) => response.data.data
		);
	};

	update = (id, data) => {
		return this.Client.put(ENDPOINTS.EQUIPMENT.SLEEVES.UPDATE(id), data).then(
			(response) => response.data.data
		);
	};

	delete = (data) => {
		return this.Client.delete(ENDPOINTS.EQUIPMENT.SLEEVES.DELETE(data)).then(
			(response) => response
		);
	};
}

export default SleevesAPI;
