// Imports => Constants
import { ENDPOINTS } from '@constants';

export class VibratoryHammersAPI {
	constructor(Client, config) {
		this.Client = Client;
		this.config = config;
	}

	list = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT.VIBRATORY_HAMMERS.LIST, {
			params,
		}).then((response) => response.data);
	};

	table = (params) => {
		return this.Client.get(ENDPOINTS.EQUIPMENT.VIBRATORY_HAMMERS.TABLE, {
			params,
		}).then((response) => response.data);
	};

	get_by_id = (id) => {
		return this.Client.get(
			ENDPOINTS.EQUIPMENT.VIBRATORY_HAMMERS.GET_BY_ID(id)
		).then((response) => response.data.data);
	};

	store = (data) => {
		return this.Client.post(
			ENDPOINTS.EQUIPMENT.VIBRATORY_HAMMERS.STORE,
			data
		).then((response) => response.data.data);
	};

	update = (id, data) => {
		return this.Client.put(
			ENDPOINTS.EQUIPMENT.VIBRATORY_HAMMERS.UPDATE(id),
			data
		).then((response) => response.data.data);
	};

	delete = (data) => {
		return this.Client.delete(
			ENDPOINTS.EQUIPMENT.VIBRATORY_HAMMERS.DELETE(data)
		).then((response) => response);
	};
}

export default VibratoryHammersAPI;
