// Imports => Constants
import { ENDPOINTS } from '@constants';

export class JeanLutzAPI {
	constructor(Client, config) {
		this.Client = Client;
		this.config = config;
	}

	control_units = (params) => {
		return this.Client.get(ENDPOINTS.JEAN_LUTZ.CONTROL_UNITS, {
			params,
		}).then((response) => response.data.data);
	};

	hydrohammers = (params) => {
		return this.Client.get(ENDPOINTS.JEAN_LUTZ.HYDROHAMMERS, { params }).then(
			(response) => response.data
		);
	};

	vibratory_hammers = (params) => {
		return this.Client.get(ENDPOINTS.JEAN_LUTZ.VIBRATORY_HAMMERS, {
			params,
		}).then((response) => response.data);
	};

	sleeves = (params) => {
		return this.Client.get(ENDPOINTS.JEAN_LUTZ.SLEEVES, { params }).then(
			(response) => response.data
		);
	};

	pulses = (params) => {
		return this.Client.get(ENDPOINTS.JEAN_LUTZ.PULSES, { params }).then(
			(response) => response.data
		);
	};

	powerpacks = (params) => {
		return this.Client.get(ENDPOINTS.JEAN_LUTZ.POWERPACKS, { params }).then(
			(response) => response.data
		);
	};
}

export default JeanLutzAPI;
